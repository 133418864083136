import { modeloApi } from "./modeloApi";

export class modeloService {
    static async getModelos() {
        try {

            const modelos = await modeloApi.getModelos();
            return modelos;
        } catch (error) {
            console.error(error);
        }
    }

    static async getModeloById(id) {
        try {
            const modelo = await modeloApi.getModeloById(id);
            return modelo[0];
        } catch (error) {
            console.error(error);
        }
    }

    static async downloadExample(formData){
        try {
            const response = await modeloApi.downloadExample(formData);
            return response;
        } catch (error) {
            console.error(error);
        }
    }

    static async downloadAll(formData){
        try {
            const response = await modeloApi.downloadAll(formData);
            return response;
        } catch (error) {
            console.error(error);
        }
    }

    static async downloadRecibos(formData){
        try {
            const response = await modeloApi.downloadRecibos(formData);
            return response;
        } catch (error) {
            console.error(error);
        }
    }

    static async downloadRegistroById(id){
        try {
            const response = await modeloApi.downloadRegistroById(id);
            return response;
        } catch (error) {
            console.error(error);
        }
    }

    static async sendEmails(formData){
        try {
            const response = await modeloApi.sendEmails(formData);
            return response;
        } catch (error) {
            console.error(error);
        }
    }

    static async resendEmail(id){
        try {
            const response = await modeloApi.resendEmail(id);
            return response;
        } catch (error) {
            console.error(error);
        }
    }

    static async createModelo(formData){
        try {
            const response = await modeloApi.createModelo(formData);
            return response;
        } catch (error) {
            throw error;
        }
    }

    static async updateModelo(formData, id){
        try {
            const response = await modeloApi.updateModelo(formData, id);
            return response;
        } catch (error) {
            throw error;
        }
    }

    static async deleteModelo(id){
        try {
            const response = await modeloApi.deleteModelo(id);
            return response;
        } catch (error) {
            console.error(error);
        }
    }

    static async getRegistros() {
        try {
            const response = await modeloApi.getRegistros();
            return response;
        } catch (error) {
            console.error(error);
        }
    }
}