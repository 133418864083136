import * as React from 'react'

function AddTable(props) {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        transform="matrix(.62 0 0 .62 16 16) translate(-27.49 -27.49)"
        d="M24.984 4.986A1 1 0 0024.84 5H6a1 1 0 00-1 1v12.832a1 1 0 000 .326v12.674a1 1 0 000 .326V44a1 1 0 001 1h18.832a1 1 0 00.326 0H29.111a11.913 11.913 0 01-.715-2H26V33H30.283a12.2 12.2 0 011.819-2H26V20h17v8.396c.693.181 1.361.42 2 .715v-9.943a1 1 0 000-.326V6a1 1 0 00-1-1H25.154a1 1 0 00-.17-.014zM7 7h17v11H7V7zm19 0h17v11H26V7zM7 20h17v11H7V20zm33 10c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10zm0 2c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8 3.6-8 8-8zM7 33h17v10H7V33zm33 1.1c-.6 0-1 .4-1 1V39h-3.9c-.6 0-1 .4-1 1s.4 1 1 1H39v3.9c0 .6.4 1 1 1s1-.4 1-1V41h3.9c.6 0 1-.4 1-1s-.4-1-1-1H41v-3.9c0-.6-.4-1-1-1z"
      />
    </svg>
  )
}

export default AddTable
