import * as React from 'react'

function DeleteRow(props) {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        transform="matrix(.56 0 0 .56 16 16) translate(-25 -29.5)"
        d="M3 9c-1.645 0-3 1.355-3 3v26c0 1.645 1.355 3 3 3h10v-2H3c-.555 0-1-.445-1-1V12c0-.555.445-1 1-1h44c.555 0 1 .445 1 1v26c0 .555-.445 1-1 1H37v2h10c1.645 0 3-1.355 3-3V12c0-1.645-1.355-3-3-3zm22 21c-5.512 0-10 4.488-10 10s4.488 10 10 10 10-4.488 10-10-4.488-10-10-10zm0 2c4.43 0 8 3.57 8 8s-3.57 8-8 8-8-3.57-8-8 3.57-8 8-8zm-5 7v2h10v-2z"
      />
    </svg>
  )
}

export default DeleteRow
