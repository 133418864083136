import * as React from 'react'

function DeleteTable(props) {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        transform="matrix(.4 0 0 .4 16 16) translate(-45 -45)"
        d="M13 10c-1.645 0-3 1.355-3 3v54c0 1.645 1.355 3 3 3h36.178c2.378 5.857 8.12 10 14.822 10 8.825 0 16-7.175 16-16 0-6.702-4.143-12.444-10-14.822V13c0-1.645-1.355-3-3-3H13zm0 2h16a1 1 0 002 0h18a1 1 0 102 0h16c.565 0 1 .435 1 1v16a1 1 0 000 2v17.527A15.93 15.93 0 0064 48c-8.825 0-16 7.175-16 16 0 1.383.196 2.719.527 4H31a1 1 0 00-2 0H13c-.565 0-1-.435-1-1V51a1 1 0 100-2V31a1 1 0 000-2V13c0-.565.435-1 1-1zm17 3a1 1 0 100 2 1 1 0 000-2zm20 0a1 1 0 100 2 1 1 0 000-2zm-20 4a1 1 0 100 2 1 1 0 000-2zm20 0a1 1 0 100 2 1 1 0 000-2zm-20 4a1 1 0 100 2 1 1 0 000-2zm20 0a1 1 0 100 2 1 1 0 000-2zm-20 4a1 1 0 100 2 1 1 0 000-2zm20 0a1 1 0 100 2 1 1 0 000-2zm-34 2a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm-34 2a1 1 0 100 2 1 1 0 000-2zm20 0a1 1 0 100 2 1 1 0 000-2zm-20 4a1 1 0 100 2 1 1 0 000-2zm20 0a1 1 0 100 2 1 1 0 000-2zm-20 4a1 1 0 100 2 1 1 0 000-2zm20 0a1 1 0 100 2 1 1 0 000-2zm-20 4a1 1 0 100 2 1 1 0 000-2zm20 0a1 1 0 100 2 1 1 0 000-2zm-20 4a1 1 0 100 2 1 1 0 000-2zm20 0a1 1 0 100 2 1 1 0 000-2zm-34 2a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm16 1c7.744 0 14 6.256 14 14s-6.256 14-14 14-14-6.256-14-14 6.256-14 14-14zm-34 1a1 1 0 100 2 1 1 0 000-2zm0 4a1 1 0 100 2 1 1 0 000-2zm29.707 3.293l-1.414 1.414L62.586 64l-4.293 4.293 1.414 1.414L64 65.414l4.293 4.293 1.414-1.414L65.414 64l4.293-4.293-1.414-1.414L64 62.586l-4.293-4.293zM30 59a1 1 0 100 2 1 1 0 000-2zm0 4a1 1 0 100 2 1 1 0 000-2z"
      />
    </svg>
  )
}

export default DeleteTable
