import * as React from 'react'

function AddColumnLeft(props) {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        transform="matrix(.56 0 0 .56 16 16) translate(-24.99 -27.5)"
        d="M15 5c-.55 0-1 .45-1 1v5H3.414l3.293-3.293a.997.997 0 00.223-1.105.986.986 0 00-.95-.61.971.971 0 00-.687.301l-4.887 4.89a.977.977 0 00-.386.575c-.11.414.058.851.414 1.09l.007.007 4.852 4.852c.25.262.625.367.973.273a.99.99 0 00.714-.714.992.992 0 00-.273-.973L3.414 13H14v31c0 .55.45 1 1 1h17.5c.14 0 .281-.027.41-.082C34.371 47.918 37.445 50 41 50c4.957 0 9-4.043 9-9s-4.043-9-9-9a8.94 8.94 0 00-5 1.523V6c0-.55-.45-1-1-1zm1 2h18v28.36A8.937 8.937 0 0032 41c0 .688.086 1.355.234 2H16V12.168a.904.904 0 000-.324zm25 27c3.879 0 7 3.121 7 7s-3.121 7-7 7-7-3.121-7-7 3.121-7 7-7zm-1 3v3h-3v2h3v3h2v-3h3v-2h-3v-3z"
      />
    </svg>
  )
}

export default AddColumnLeft
