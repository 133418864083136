import React from 'react'
import * as Dialog from '@radix-ui/react-dialog';
import * as S from './styles'

export function DialogRoot({children, ...props}) {
  return (
    <Dialog.Root {...props}>
        {children}
    </Dialog.Root>
  )
}

export function DialogTrigger({children, ...props}) {
  return (
    <Dialog.Trigger {...props}>
        {children}
    </Dialog.Trigger>
  )
}

export function DialogContent({children}) {
  return (
    <Dialog.Content asChild>
        <S.DialogContent>
            {children}
        </S.DialogContent>
    </Dialog.Content>
  )
}

export function DialogClose({children, ...props}) {
  return (
    <Dialog.Close {...props}>
        {children}
    </Dialog.Close>
  )
}

export function DialogOverlay({children, ...props}) {
  return (
    <Dialog.Overlay asChild>
        <S.DialogOverlay>
            {children}
        </S.DialogOverlay>
    </Dialog.Overlay>
  )
}

export function DialogPortal({children, ...props}) {
  return (
    <Dialog.Portal {...props}>
        {children}
    </Dialog.Portal>
  )
}

export function DialogTitle({children, ...props}) {
  return (
    <Dialog.Title {...props}>
        {children}
    </Dialog.Title>
  )
}

export function DialogDescription({children, ...props}) {
  return (
    <Dialog.Description {...props}>
        {children}
    </Dialog.Description>
  )
}