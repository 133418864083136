import * as React from 'react'

function SplitCells(props) {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        transform="matrix(.45 0 0 .45 16 16) translate(-40 -39.99)"
        d="M39.984 8.986A1 1 0 0039.84 9H12a1 1 0 00-1 1v20a1 1 0 001 1h27.832a1 1 0 00.326 0H68a1 1 0 001-1V10a1 1 0 00-1-1H40.154a1 1 0 00-.17-.014zM13 11h26v18H13V11zm28 0h26v18H41V11zM12 33a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm-56 4a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm-56 4a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm-56 4a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm-56 4a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm-56 4a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm-56 4a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm-56 4a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm-56 4a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm-56 4a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2z"
      />
    </svg>
  )
}

export default SplitCells
