import * as React from 'react'

function AddRowAfter(props) {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        transform="matrix(.62 0 0 .62 16 16) translate(-27.5 -32.01)"
        d="M6 14a1 1 0 00-1 1v20a1 1 0 001 1h5v10.586l-3.293-3.293a1 1 0 10-1.414 1.414l4.857 4.857a1 1 0 00.864.45 1 1 0 00.84-.453l4.853-4.854a1 1 0 10-1.414-1.414L13 46.586V36h15.697a12.01 12.01 0 01.92-2H7V16h36v12.393c.693.179 1.361.415 2 .709V15a1 1 0 00-1-1H6zm34 16c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10zm0 2c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8 3.6-8 8-8zm0 2.1c-.6 0-1 .4-1 1V39h-3.9c-.6 0-1 .4-1 1s.4 1 1 1H39v3.9c0 .6.4 1 1 1s1-.4 1-1V41h3.9c.6 0 1-.4 1-1s-.4-1-1-1H41v-3.9c0-.6-.4-1-1-1z"
      />
    </svg>
  )
}

export default AddRowAfter
