import * as React from 'react'

function AddRowAbove(props) {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        transform="matrix(.56 0 0 .56 16 16) translate(-27.5 -25)"
        d="M11.98-.01a1 1 0 00-.797.412l-4.89 4.891a1 1 0 101.414 1.414L11 3.414V14H6a1 1 0 00-1 1v20a1 1 0 001 1h22.697a12.01 12.01 0 01.92-2H7V16h4.832a1 1 0 00.326 0H43v12.393c.693.179 1.361.415 2 .709V15a1 1 0 00-1-1H13V3.414l3.293 3.293a1 1 0 101.414-1.414L12.824.41a1 1 0 00-.844-.42zM40 30c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10zm0 2c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8 3.6-8 8-8zm0 2.1c-.6 0-1 .4-1 1V39h-3.9c-.6 0-1 .4-1 1s.4 1 1 1H39v3.9c0 .6.4 1 1 1s1-.4 1-1V41h3.9c.6 0 1-.4 1-1s-.4-1-1-1H41v-3.9c0-.6-.4-1-1-1z"
      />
    </svg>
  )
}

export default AddRowAbove
