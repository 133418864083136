import * as React from 'react'

function AddColumnRigth(props) {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        transform="matrix(.62 0 0 .62 16 16) translate(-31.51 -27.5)"
        d="M14 5c-.55 0-1 .45-1 1v38c0 .55.45 1 1 1h18.402c.168.004.34-.04.489-.121C34.348 47.902 37.43 50 41 50c4.957 0 9-4.043 9-9s-4.043-9-9-9a8.946 8.946 0 00-6 2.313V13h11.586l-3.293 3.293a.992.992 0 00-.273.973.99.99 0 00.714.714.992.992 0 00.973-.273l4.855-4.86a.992.992 0 00.43-1.038c0-.008 0-.012-.004-.016a.986.986 0 00-.394-.613l-4.887-4.887a1.004 1.004 0 00-1.645.32 1 1 0 00.231 1.094L46.586 11H35V6c0-.55-.45-1-1-1zm1 2h18v29.598c0 .082.008.164.027.242A8.945 8.945 0 0032 41c0 .688.086 1.355.234 2H15zm26 27c3.879 0 7 3.121 7 7s-3.121 7-7 7-7-3.121-7-7 3.121-7 7-7zm-1 3v3h-3v2h3v3h2v-3h3v-2h-3v-3z"
      />
    </svg>
  )
}

export default AddColumnRigth
