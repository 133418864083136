import {
    Container,
    Div,
  } from './styles'
  
    
  import { EditorContent, useEditor } from '@tiptap/react'

  import StarterKit from '@tiptap/starter-kit'
  import Underline from '@tiptap/extension-underline'
  import { Color } from '@tiptap/extension-color'
  import TextStyle from '@tiptap/extension-text-style'
  import Highlight from '@tiptap/extension-highlight'
    
  import Table from '@tiptap/extension-table'
  import TableRow from '@tiptap/extension-table-row'
  import TableHeader from '@tiptap/extension-table-header'
  import TableCell from '@tiptap/extension-table-cell'
  import TextAlign from '@tiptap/extension-text-align'
  import FixedImage from '../../components/InputNotation/FixedImage'
  
  export const extensions = [
      StarterKit.configure({
        considerAnyAsEmpty: true,
      }),
      Underline,
      TextStyle,
      Color,
      Highlight.configure({ multicolor: true }),
      Table,
      TableRow,
      TableHeader,
      TableCell,
      // Image.configure({
      //   allowBase64: true,
      // })
      FixedImage.configure({
        allowBase64: true,
      }),
      // Heading,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
    ]
    const InputNotationView = ({content}) => {
        const defaultEditor = useEditor({
            editable: false,
            extensions,
            content: content,
            // content: '<h2 style="text-align: center">Recibo de Vale Refeição</h2><p>Lorem ipsum dolor sit amet {nome} adipisicing elit. Saepe quo, aliquam consectetur accusamus nulla repellat quas? Incidunt commodi ad mollitia, recusandae sapiente, voluptate molestiae, impedit officia non enim explicabo. Assumenda.</p><p><strong>teste</strong></p><p><u>teste</u></p><p><em>teste</em></p><p><span style="color: rgb(255, 0, 0)">teste</span></p><p><span style="color: rgb(0, 0, 0)"><mark data-color="#ffe066" style="background-color: #ffe066; color: inherit">teste</mark></span></p><ol><li><p>a</p></li><li><p>b</p></li><li><p>c</p></li></ol><ul><li><p>a</p></li><li><p>b</p></li><li><p>c</p></li></ul><table style="minWidth: 75px"><colgroup><col><col><col></colgroup><tbody><tr><th colspan="1" rowspan="1"><p>teste</p></th><th colspan="1" rowspan="1"><p>teste</p></th><th colspan="1" rowspan="1"><p>teste</p></th></tr><tr><td colspan="1" rowspan="1"><p>a</p></td><td colspan="1" rowspan="1"><p>a</p></td><td colspan="1" rowspan="1"><p>s</p></td></tr><tr><td colspan="1" rowspan="1"><p>z</p></td><td colspan="1" rowspan="1"><p>a</p></td><td colspan="1" rowspan="1"><p>a</p></td></tr></tbody></table><br><p>Fortaleza, 12/04/2023</p><br><p style="text-align: center">______________________</p><p style="text-align: center">{nome}</p><p>1</p><p>2</p><p>3</p>',
          })
      if (!defaultEditor) {
        return null
      }
    
      return (
        <Container>
          <Div>
            <EditorContent editor={defaultEditor} />
          </Div>
        </Container>
      )
    }
    
    export default InputNotationView
    