import Image from '@tiptap/extension-image';
import { Plugin } from 'prosemirror-state';

const FixedImage = Image.extend({
  addOptions() {
    return {
      ...this.parent?.(),
      allowBase64: true, // Configuração para permitir Base64
    };
  },
  addAttributes() {
    return {
      ...this.parent?.(),
      src: {
        default: null,
        renderHTML: attributes => {
          if (!attributes.src) {
            return {};
          }
          return {
            src: attributes.src,
            style: 'pointer-events: none;', // Desabilita interações no frontend
          };
        },
      },
    };
  },
  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          handleDOMEvents: {
            mousedown(view, event) {
              const { schema } = view.state;
              const { doc } = view.state;
              const pos = view.posAtCoords({ left: event.clientX, top: event.clientY });

              if (pos) {
                const node = doc.nodeAt(pos.pos);
                if (node && node.type === schema.nodes.image) {
                  event.preventDefault();
                  return true;
                }
              }
              return false;
            },
          },
        },
      }),
    ];
  },
});

export default FixedImage;
