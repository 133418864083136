import {
  Container,
  Div,
} from './styles'

  
import { EditorContent } from '@tiptap/react'
import Menubar from './Menubar.jsx'
  
  const InputNotation = ({defaultEditor}) => {

    // useEffect(() => {
    //   if (defaultEditor) {
    //     defaultEditor.commands.setContent(corpoHTML)
    //   }
    // }, [corpoHTML, defaultEditor])

    // const handleSubmit = async (e, editor) => {
    //   e.preventDefault()
    //   if (editor.isEmpty) {
    //     return
    //   }
    // }
  
    if (!defaultEditor) {
      return null
    }
  
    return (
      <Container>
        <Div>
          <Menubar editor={defaultEditor} />
          <EditorContent editor={defaultEditor} />
        </Div>
      </Container>
    )
  }
  
  export default InputNotation
  