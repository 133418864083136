import * as React from 'react'

function TableIcon(props) {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        transform="matrix(.67 0 0 .67 16 16) translate(-25 -25)"
        d="M24.984 3.986A1 1 0 0024.84 4H5a1 1 0 00-1 1v12.832a1 1 0 000 .326v13.674a1 1 0 000 .326V45a1 1 0 001 1h19.832a1 1 0 00.326 0H45a1 1 0 001-1V32.168a1 1 0 000-.326V18.168a1 1 0 000-.326V5a1 1 0 00-1-1H25.154a1 1 0 00-.17-.014zM6 6h18v11H6V6zm20 0h18v11H26V6zM6 19h18v12H6V19zm20 0h18v12H26V19zM6 33h18v11H6V33zm20 0h18v11H26V33z"
      />
    </svg>
  )
}

export default TableIcon
