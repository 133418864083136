import * as Tooltip from '@radix-ui/react-tooltip'
import { ContentContainer } from './styles'

const ToolTip = ({ $trigger, $content }) => (
  <Tooltip.Provider>
    <Tooltip.Root>
      <Tooltip.Trigger type="button">{$trigger}</Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Content>
          <ContentContainer>{$content}</ContentContainer>
          <Tooltip.Arrow />
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  </Tooltip.Provider>
)

export default ToolTip
