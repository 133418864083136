import * as React from 'react'

function IconPincel(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={14}
      viewBox="0 0 20 19"
      fill="none"
      {...props}
    >
      <path
        d="M10.714 9.718L8.732 7.736l-3.789 3.781a.204.204 0 00-.06.15c0 .06.02.109.06.15l1.683 1.682c.04.046.09.068.15.068a.194.194 0 00.15-.068l3.788-3.781zm-1.405-2.56l1.982 1.982 3.69-3.69a.212.212 0 00.062-.157.212.212 0 00-.062-.156l-1.668-1.669a.212.212 0 00-.157-.061.212.212 0 00-.157.061l-3.69 3.69zm-.86-.283L11.574 10l-3.953 3.953c-.211.21-.494.316-.848.316-.355 0-.64-.105-.855-.316l-.202-.208-.857.856H2.688l1.934-1.934-.098-.105a1.175 1.175 0 01-.357-.887c.004-.355.126-.653.368-.894l3.913-3.907zm0 0l4.02-4.02a.988.988 0 01.727-.294c.288 0 .53.098.727.294l1.643 1.643c.196.196.292.45.288.761-.003.312-.103.566-.3.762L11.575 10 8.449 6.875z"
        fill="#1C1B1F"
      />
    </svg>
  )
}

export default IconPincel
