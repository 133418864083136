import {
    ResgistroContainer,
    ResgistroContainerRow
} from './styles';

import {
    FooterItem,
    Button,
    Container,
    ContainerCollumContent,
    ContainerRow,
    BoxForm,
    BoxContent,
    Title
} from '../../styles/shared';

import { useState, useEffect } from 'react';
import Input from '../../components/Input';
import TextArea from '../../components/TextArea';
import { modeloService } from '../Home/services/modeloService';

import { Toaster, toast } from 'sonner';

import{
    ArrowCircleLeft,
    FileArrowDown,
    PaperPlaneRight,
    SignOut
} from "@phosphor-icons/react";
import{
    AccordionContent,
    AccordionHeader,
    AccordionItem,
    AccordionRoot,
    AccordionTrigger
} from './Accordion';
import { useAuth } from '../../hooks/auth';
import InputNotationView from '../../components/InputNotationView';

const Registro = () => {
    const [registros, setRegistros] = useState([]);
    const [activeAccordion, setActiveAccordion] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const {logout} = useAuth();
    const loadRegistros = async () => {
        try {
            const response = await modeloService.getRegistros();
            /**
             * Transforma o objeto em um array de objetos
             * Pois assim é possivel usar map e filter
             */
            // console.log(response.data);
            const repArray = Object.entries(response.data)
            const finalArray = repArray.map((item) => {
                return {
                    titulo_registro: item[0],
                    pessoas: Object.entries(item[1]).map((pessoa) => {
                        return {
                            nome_registro: pessoa[0],
                            conteudo_registro: Object.entries(pessoa[1]).map((conteudo) => {
                                return {
                                    id_registro: conteudo[1].id_registro,
                                    nome_registro: conteudo[1].nome_registro,
                                    email_registro: conteudo[1].email_registro,
                                    corpo_registro: conteudo[1].corpo_registro,
                                    local_data_registro: conteudo[1].local_data_registro,
                                    assinante_registro: conteudo[1].assinante_registro,
                                    assunto_registro: conteudo[1].assunto_registro,
                                    corpo_email_registro: conteudo[1].corpo_email_registro,
                                    empresa_registro: conteudo[1].empresa_registro,
                                    setor_registro: conteudo[1].setor_registro,
                                    documento_registro: conteudo[1].documento_registro,
                                    copia_registro: conteudo[1].copia_registro !== null && conteudo[1].copia_registro !== '' && conteudo[1].copia_registro !== undefined
                                    ? conteudo[1].copia_registro : 'Sem cópia',
                                }
                            })
                        }
                    })
                }
            })
            // console.log(finalArray);
            setRegistros(finalArray);
        } catch (error) {
            console.error(error);
        }
    }

    const getRegistroEmpresa = (registro) => {
        return registro.pessoas[0].conteudo_registro[0].empresa_registro;
    }

    const handleAccordionClick = (value) => {
        if(activeAccordion === value){
            setActiveAccordion('');
        }else{
            setActiveAccordion(value);
        }
    }

    const checkIncludes = (nome, email, titulo) => {
        const hasNome = nome.toLowerCase().includes(filterValue.toLowerCase());
        const hasEmail = email.toLowerCase().includes(filterValue.toLowerCase());
        const hasTitulo = titulo.toLowerCase().includes(filterValue.toLowerCase());
        return hasNome || hasEmail || hasTitulo
    }

    const handleFilter = (registro) => {
        const hasNameOrTitleOrEmail = registro.pessoas.map((pessoa) => {
            return pessoa.conteudo_registro.map((conteudo) =>{
                return {
                    nome: conteudo.nome_registro.toLowerCase(),
                    email: conteudo.email_registro.toLowerCase(),
                    titulo: registro.titulo_registro.toLowerCase()
                }
            })
        }).flat().some(item => {
            return checkIncludes(item.nome, item.email, item.titulo)
        })
        return hasNameOrTitleOrEmail
    }

    const donwloadRegistro = async (id, name) => {
        try {
            const res = await modeloService.downloadRegistroById(id);
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${name}.pdf`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.log(error);
        }
    }

    const resendRegistro = async (id) => {
        try {
            // toast.success('O documento está sendo reenviado...');
            await modeloService.resendEmail(id);
            toast.success('O documento está sendo reenviado...');
        } catch (error) {
            console.log(error);
        }
    
    }

    useEffect(() => {
        loadRegistros()
    }, []);

    return (
        <Container>
            <ContainerRow>
                <ContainerCollumContent>
                    <BoxForm>
                        <Title>Registros de Envio</Title>
                        <ResgistroContainerRow>
                            <Input
                                value={filterValue}
                                label='Filtro'
                                type="text"
                                placeholder={'Digite o nome, email ou título do PDF'}
                                onChange={(e) => setFilterValue(e.target.value)}
                            />
                            <FooterItem style={{alignItems: 'end', justifyContent: 'end'}}>
                                <Button onClick={() => window.location.href = '/'}>
                                    <ArrowCircleLeft />
                                    Voltar
                                </Button>
                                <Button onClick={logout}>
                                    <SignOut />
                                    Logout
                                </Button>
                            </FooterItem>
                            
                        </ResgistroContainerRow>

                        <BoxContent>
                            <AccordionRoot>
                                {
                                registros?.filter(registro => handleFilter(registro)).map((registro) => {
                                    return(
                                        <AccordionItem key={registro.titulo_registro} value={`item-${registro.titulo_registro}`}>
                                            <AccordionHeader>
                                                <AccordionTrigger>
                                                    {`[${getRegistroEmpresa(registro)}] ${registro.titulo_registro}`}
                                                </AccordionTrigger>
                                            </AccordionHeader>
                                            <AccordionContent>
                                                {
                                                    registro.pessoas.filter(pessoa => {
                                                        return pessoa.conteudo_registro.some(conteudo => {
                                                            return checkIncludes(conteudo.nome_registro, conteudo.email_registro, registro.titulo_registro)
                                                        })
                                                    }).map((pessoa) => {
                                                        return (
                                                            <AccordionRoot key={pessoa.nome_registro} value={activeAccordion}>
                                                                {
                                                                    pessoa.conteudo_registro.filter(conteudo => 
                                                                        checkIncludes(conteudo.nome_registro, conteudo.email_registro, registro.titulo_registro)
                                                                        ).map((conteudo) => (
                                                                        <AccordionItem key={conteudo.id_registro} value={`item-conteudo-${conteudo.id_registro}`}>
                                                                            <AccordionHeader>
                                                                                <AccordionTrigger $bg='#e6e6e6' $bgHover='#d7d7d7' onClick={() => handleAccordionClick(`item-conteudo-${conteudo.id_registro}`)}>
                                                                                    {`${conteudo.nome_registro}`}
                                                                                </AccordionTrigger>
                                                                            </AccordionHeader>
                                                                            <AccordionContent>
                                                                                <ResgistroContainer >
                                                                                    <ResgistroContainerRow>
                                                                                        <Input value={conteudo.empresa_registro} disabled label="Empresa" type="text"/>
                                                                                        <Input value={conteudo.setor_registro} disabled label="Setor" type="text"/>
                                                                                    </ResgistroContainerRow>
                                                                                    <ResgistroContainerRow>
                                                                                        <Input value={conteudo.nome_registro} disabled label="Nome" type="text"/>
                                                                                        <Input value={conteudo.email_registro} disabled label="Email" type="text"/>
                                                                                    </ResgistroContainerRow>
                                                                                    {/* <Input value={registro.titulo_registro} disabled label="Título do PDF" type="text"/> */}
                                                                                    {/* <TextArea value={conteudo.documento_registro} disabled label="Corpo do PDF" type="text" /> */}
                                                                                    <InputNotationView content={conteudo.documento_registro}/>                                                                        
                                                                                    <ResgistroContainerRow>
                                                                                        {/* //<Input value={conteudo.local_data_registro} disabled label="Local e data" type="text"/> */}
                                                                                        {/* // <Input value={conteudo.assinante_registro} disabled label="Assinante" type="text"/> */}
                                                                                    </ResgistroContainerRow>
                                                                                    <Input value={conteudo.assunto_registro} disabled label="Título do email" type="text" />
                                                                                    <TextArea value={conteudo.corpo_email_registro} disabled label="Corpo do email" type="text" />
                                                                                    <Input value={conteudo.copia_registro} disabled label="Email de cópia" type="text" />
                                                                                    <FooterItem>
                                                                                        <Button onClick={() => donwloadRegistro(conteudo.id_registro, conteudo.nome_registro)}>
                                                                                            <FileArrowDown />
                                                                                            Baixar documento
                                                                                        </Button>
                                                                                        <Button onClick={() => resendRegistro(conteudo.id_registro)}>
                                                                                            <PaperPlaneRight/>
                                                                                            Reenviar documento
                                                                                        </Button>
                                                                                    </FooterItem>
                                                                                </ResgistroContainer>        
                                                                            </AccordionContent>
                                                                        </AccordionItem>
                                                                    ))
                                                                }
                                                            </AccordionRoot>
                                                        )
                                                    })
                                                }
                                            </AccordionContent>
                                        </AccordionItem>
                                        )
                                    })
                                }
                            </AccordionRoot>
                        </BoxContent>
                    </BoxForm>
                </ContainerCollumContent>
            </ContainerRow>
            <Toaster position="top-right" richColors duration={1000 * 2.5}/>
        </Container>
    );
};

export default Registro;