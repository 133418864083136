import styled from 'styled-components'
import IconPincel from '../../assets/icons/Pincel.jsx'

export const Container = styled.div`
  width: 100%;
  /* height: 283px; */
  flex-shrink: 0;
  /* border-radius: 20px; */
  background: #FFFFFF;
  /* padding: 20px; */
  padding: 0.5rem;
  border: 1px solid #464646;
  border-radius: 5px;
`

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .tiptap {
    /* height: calc(600px - 0.5rem); */
    outline: none;
    padding: 0.5rem;
    border: none;
    /* border-bottom: 1px solid #000; */
    color: #1C1B1F;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 684px;
    overflow-y: auto;
    > * + * {
      margin-top: 0.75em;
    }

    strong {
      font-weight: bold;
    }

    ul,
    ol {
      padding: 0 1rem;
    }

  h1, 
  h2, 
  h3, 
  h4, 
  h5, 
  h6 {
    line-height: 1.1;
    /* margin-top: 2.5rem; */
    font-weight: bold;
    /* text-wrap: pretty; */
  }

  h1, 
  h2 {
    /* margin-top: 3.5rem; */
    margin-bottom: 1.5rem;
  }

  h1 { 
    font-size: 1.4rem; 
  }

  h2 { 
    font-size: 1.2rem; 
  }

  h3 { 
    font-size: 1.1rem; 
  }

  h4, 
  h5, 
  h6 { 
    font-size: 1rem; 
  }
    h1 *, h2 *, h3 *, h4 *, h5 *, h6 * {
    font-size: inherit;
  }

    code {
      background-color: rgba(#616161, 0.1);
      color: #616161;
    }

    pre {
      background: #0d0d0d;
      color: #fff;
      font-family: 'JetBrainsMono', monospace;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;

      code {
        color: inherit;
        padding: 0;
        background: none;
        font-size: 0.8rem;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }

    blockquote {
      padding-left: 1rem;
      border-left: 2px solid rgba(#0d0d0d, 0.1);
    }

    hr {
      border: none;
      border-top: 2px solid rgba(#0d0d0d, 0.1);
      margin: 2rem 0;
    }

    //table css
    table {
      border-collapse: collapse;
      margin: 0;
      overflow: hidden;
      table-layout: fixed;
      width: 100%;

      td,
      th {
        border: 2px solid #ced4da;
        box-sizing: border-box;
        min-width: 1em;
        padding: 3px 5px;
        position: relative;
        vertical-align: top;

        > * {
          margin-bottom: 0;
        }
      }

      th {
        background-color: #f1f3f5;
        font-weight: bold;
        text-align: left;
      }

      .selectedCell:after {
        background: rgba(200, 200, 255, 0.4);
        content: '';
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        pointer-events: none;
        position: absolute;
        z-index: 2;
      }

      .column-resize-handle {
        background-color: #adf;
        bottom: -2px;
        position: absolute;
        right: -2px;
        pointer-events: none;
        top: 0;
        width: 4px;
      }

      p {
        margin: 0;
      }
    }
  }
`

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`

export const Label = styled.label`
  color: #383838;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: underline;
  text-decoration-color: #2ecc71;
`

export const LabelGray = styled.label`
  color: #ccc;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const LabelMiniContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`

export const TextArea = styled.textarea`
  width: 75%;
  height: 150px;
  border: none;
  border-bottom: 1px solid #000;
  resize: none;
  color: #1C1B1F;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  outline: none;
`

export const DivBottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const ActionsCoantainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;

  p,
  svg {
    cursor: pointer;
  }

  .is-active {
    /* background-color: red; */
    color: #3E3EF9;
    fill: #3E3EF9;
  }
`

export const BoldAction = styled.p`
  color: #1C1B1F;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

export const SublinhadoAction = styled.p`
  color: #1C1B1F;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
`

export const ColorText = styled.p`
  color: #1C1B1F;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  border-bottom: 3px solid ${(props) => props.$color};
  text-align: center;
  width: 28px;
  height: 22px;
`

export const ColorsContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  background-color: #F2EFFA;
  padding: 1rem;
  margin-top: 0.5rem;
  max-width: 122px;
  z-index: 999;
`

export const TableIconsContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  background-color: #F2EFFA;
  padding: 1rem;
  margin-top: 0.5rem;
  max-width: 179px;
  z-index: 999;
`

export const ColorSquare = styled.div`
  width: 15px;
  height: 15px;
  /* border-radius: 0.5rem; */
  border: 1px solid black;
  background-color: ${(props) => props.$color};
  cursor: pointer;
`

export const IconPopoverContainer = styled.div`
  display: flex;
`

export const ActionA = styled.p`
  color: #FFFFFF;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #1C1B1F;
  width: 21px;
  height: 21px;
  flex-shrink: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const IconPincelStyled = styled(IconPincel)`
  width: 21px;
  height: 21px;
  flex-shrink: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #6DF6FF;
`

export const IconPincelStyledUnderline = styled(IconPincel)`
  width: 28px;
  height: 28px;
  border-bottom: 3px solid ${(props) => props.$color};
  flex-shrink: 0;
`

export const AddButton = styled.button`
  display: inline-flex;
  padding: 4px 6px;
  align-items: center;
  gap: 7px;
  border-radius: 10px;
  background: #3E3EF9;
  color: #FFFFFF;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  cursor: pointer;
`

export const FilesRow = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`

export const FileWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background: #E5E1E6;
  padding: 0.5rem;
  border-radius: 10px;
`
