import * as React from 'react'

function MergeCells(props) {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        transform="matrix(.45 0 0 .45 16 16) translate(-40 -40)"
        d="M12 9a1 1 0 00-1 1v20a1 1 0 001 1h56a1 1 0 001-1V10a1 1 0 00-1-1H12zm1 2h54v18H13V11zm-1 22a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm-56 4a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm-56 4a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm-56 4a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm-56 4a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm-56 4a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm-56 4a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm-56 4a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm-56 4a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm28 0a1 1 0 100 2 1 1 0 000-2zm-56 4a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2z"
      />
    </svg>
  )
}

export default MergeCells
